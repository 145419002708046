import { ToastsAction } from './constants';

export const removeToast = (id: string) => {
    return {
        type: ToastsAction.REMOVE_TOAST,
        payload: { id: id },
    };
};

export const addToast = (type: string, message: string, title?: string) => {
    return {
        type: ToastsAction.ADD_TOAST,
        payload: {
            id: new Date().getTime().toString(),
            type: type,
            title: title,
            message: message,
            timeout: 3000,
        },
    };
};