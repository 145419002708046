import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import HorizontalLayout from '../layouts/Horizontal/';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// constants
import { LayoutTypes } from '../constants';

// hooks
import { useRedux } from '../hooks';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// dashboards
const DashBoard1 = React.lazy(() => import('../pages/dashboards/DashBoard1/'));

// extra pages
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// Loki Pages Bots
const Bots = React.lazy(() => import('../loki-pages/bots/bots'));
const BotConversations = React.lazy(() => import('../loki-pages/bots/conversations/conversations'));

// Loki Pages Sites
const Sites = React.lazy(() => import('../loki-pages/sites/sites'));
const SiteLayout = React.lazy(() => import('../loki-pages/sites/siteLayout'));
const Site = React.lazy(() => import('../loki-pages/sites/site'));
const SitePages = React.lazy(() => import('../loki-pages/sites/pages/pages'));
const SitePage = React.lazy(() => import('../loki-pages/sites/pages/page'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { appSelector } = useRedux();

  const { layout } = appSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  return useRoutes([
    { path: '/', element: <Root /> },
    {
      // public routes
      path: '/',
      element: <DefaultLayout />,
      children: [
        {
          path: 'auth',
          children: [
            { path: 'login', element: <LoadComponent component={Login} /> },
            { path: 'logout', element: <LoadComponent component={Logout} /> },
          ],
        },
        {
          path: 'error-404',
          element: <LoadComponent component={Error404} />,
        },
        {
          path: 'not-found',
          element: <LoadComponent component={Error404} />,
        },
        {
          path: 'error-500',
          element: <LoadComponent component={Error500} />,
        },
      ],
    },
    {
      // auth protected routes
      path: '/',
      element: <PrivateRoute component={Layout} />,
      children: [
        {
          path: 'dashboard',
          element: <LoadComponent component={DashBoard1} />,
        },
        {
          path: 'bots',
          element: <LoadComponent component={Bots} />,
        },
        {
          path: 'bots/:id/conversations/:conversationId?',
          element: <LoadComponent component={BotConversations} />,
        },
        {
          path: 'sites',
          element: <LoadComponent component={Sites} />,
        },
        {
          path: 'sites/:id',
          element: <LoadComponent component={SiteLayout} />,
          children: [
            { path: 'pages', element: <LoadComponent component={SitePages} /> },
            { path: '', element: <LoadComponent component={Site} /> }
          ]
        },
        {
          path: 'sites/:siteId/pages/:pageId',
          element: <LoadComponent component={SitePage} />,
        },
      ],
    },
  ]);
};

export { AllRoutes };
