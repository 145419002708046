import { APICore } from './apiCore';
import config from '../../config';
import { emails } from '../../pages/apps/Email/data';

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
    const baseUrl = `${config.API_URL}/login`;
    return api.login(`${baseUrl}`, params.email, params.password);
}

function logout() {
    const baseUrl = `${config.API_URL}/logout/`;
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = `${config.API_URL}/register/`;
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = `${config.API_URL}/forget-password/`;
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };
