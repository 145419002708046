import { Toast } from '../../helpers/api/toast';

const INIT_STATE : { toasts: Toast[] } = {
    toasts: []
}

const Toasts = (
    state = INIT_STATE,
    action: { type: string; payload: Toast }
) => {
    switch (action.type) {
        case '@@toast/ADD_TOAST':
            return {
                ...state,
                toasts: [...state.toasts, action.payload],
            };
        case '@@toast/REMOVE_TOAST':
            return {
                ...state,
                toasts: state.toasts.filter(toast => toast.id !== action.payload.id),
            };
        default:
            return { ...state };
    }
};

export default Toasts;