import { useEffect, useState } from 'react';

// actions
import { changePageTitle } from '../redux/actions';

// hooks
import { useRedux } from '.';

export default function usePageTitle(value: {
    title: string;
    breadCrumbItems: {
        label: string;
        path: string;
        active?: boolean;
    }[];
} | undefined = undefined) {
    const { dispatch } = useRedux();

    const [pageTitle, setPageTitle] = useState(value);

    useEffect(() => {
        if(pageTitle) {
            dispatch(changePageTitle(pageTitle));
        }
    }, [dispatch, pageTitle]);

    return [setPageTitle];
}
