import { UserOrganization } from '../../helpers/api/organization';
import { OrganizationAction } from './constants';

const INIT_STATE : { organization: UserOrganization } = {
    organization: {
        name: '',
        id: ''
    }
}

const Organization = (
    state = INIT_STATE,
    action: { type: string; payload: UserOrganization }
) => {
    switch (action.type) {
        case OrganizationAction.CHANGE_ORGANIZATION:
            return {
                ...state,
                organization: action.payload,
            };
        default:
            return { ...state };
    }
};

export default Organization;